<template>
  <a-spin :spinning="isLoading">
    <sdPageHeader :title="$t.payment_history" />
    <Main>
      <!--      Tıklandıgında /payment sayfasına yöneldnirren butonu ekle -->
      <a-button
        type="primary"
        style="position: absolute;top: 30px;right: 30px"
        @click="paymentPage()"
      >
        <template #icon>
          <DownloadOutlined />
        </template>
        <sdFeatherIcons
          size="12"
          type="send"
          style="color:#ffffff;margin-right: 2px;"
        />
        &nbsp;{{ $t.makePayment }}
      </a-button>
      <a-row
        type="flex"
        justify="center"
        :gutter="16"
        align="top"
      >
        <a-col :span="24">
          <sdCards headless>
            <a-table
              :data-source="payments"
              :columns="columns"
              row-key="id"
            >
              <template #isComplete="{ record }">
                <span
                  v-if="record.is_complete === true"
                  style="color: #34C45AFF"
                >
                  {{ $t.completed }}
                </span>
                <span
                  v-else
                  style="color: #af8f34"
                >
                  {{ $t.notCompleted }}
                </span>
              </template>
              <template #isState="{ record }">
                <span
                  v-if="record.is_state"
                ><i
                  class="fa fa-check-square"
                  aria-hidden="true"
                  style="color:#34c45a ;text-shadow: 1px 1px 1px #ccc;font-size: 1.5em"
                /></span>
                <span
                  v-else
                ><i
                  class="fa fa-minus-square-o"
                  aria-hidden="true"
                  style="color: #db4747;text-shadow: 1px 1px 1px #ccc;font-size: 1.5em"
                /></span>
              </template>
            </a-table>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </a-spin>
</template>

<script>

export default {
  data() {
    return {
      payments: [],
      searchText: '',
      searchedColumn: '',
      cargoMovementList: [],
      visible: false,
      isLoading: true,
      printList: [],
      columns: [
        {
          title: this.$t.createdAt,
          dataIndex: 'created_at',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'created_at',
          },
        },
        {
          title: this.$t.package,
          dataIndex: 'package_name',
          align: 'right',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
        },
        {
          title: this.$t.total,
          dataIndex: 'total',
          align: 'right',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'total',
          },
        },
        {
          title: this.$t.isComplete,
          dataIndex: 'is_complete',
          align: 'center',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'isComplete',
          },
        },
        {
          title: this.$t.provision,
          dataIndex: 'is_state',
          align: 'center',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'isState',
          },
        },
      ],
    }
  },
  mounted() {
    this.$restMethods.postForCargongWebApp('/payment-history').then(response => {
      if ( ! response.hasError()) {
        this.payments = response.getData().payments
      }
      this.isLoading = false
    })

  },
  methods: {
    paymentPage() {
      const contractedState = localStorage.getItem('jwtContract')
      if (contractedState === '1') {
        this.$router.push({ name: 'T.PaymentContract.Page' })
      } else {
        this.$router.push({ name: 'C.Payment.Page' })
      }
    },
  },
}
</script>